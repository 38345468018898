<template>
  <LandingLayout>
    <div class="tw-flex tw-items-center tw-justify-center tw-py-10">
      <h1 class="tw-font-bold tw-text-4xl">Syarat dan Ketentuan</h1>
    </div>
    <div class="tw-flex tw-flex-col tw-py-10 tw-px-48 tw-space-y-8 tw-text-xl tw-mb-20">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tellus tellus, dignissim eu dictum id, efficitur vitae odio. Aenean tincidunt ultricies vulputate. Nam eu venenatis tellus, in sodales velit. Proin mollis odio et nisl tincidunt tristique. Integer sit amet sem eu tellus malesuada ullamcorper. In fringilla venenatis nibh nec sagittis. Morbi molestie, leo ullamcorper vehicula lobortis, lorem ipsum mollis quam, varius ultrices enim magna eget ligula. In hac habitasse platea dictumst. In porta nisi a dui aliquam, nec tempor eros maximus. Phasellus non imperdiet dolor. Vivamus eu libero eros. Ut porttitor dui tortor, eget tempus dolor vehicula id. Proin luctus metus vitae odio condimentum luctus.
      </p>
      <p>
        Nam ultricies sapien justo, sit amet posuere risus fermentum vel. Sed elementum tempus elit, ut tristique leo laoreet ac. Etiam et risus lorem. Integer hendrerit nisl massa, et dignissim ligula tempus condimentum. Aliquam erat volutpat. Mauris volutpat imperdiet sollicitudin. Proin dignissim tortor magna, eget eleifend nulla commodo non. Cras ultricies orci nec sagittis convallis. Proin eget semper odio. Ut et venenatis turpis. Nulla sit amet sodales mauris. Mauris ac erat imperdiet, placerat massa nec, tristique lacus. In hac habitasse platea dictumst.
      </p>
      <p>
        Sed dignissim, lectus vitae aliquet varius, enim magna mollis ante, non elementum nisi metus et sem. Suspendisse lobortis lacus quis lacus finibus bibendum. Praesent consectetur eget enim eget luctus. Donec id orci imperdiet, ornare nunc vel, ultrices purus. Vestibulum sollicitudin ante molestie dui varius gravida. Nam mattis, mi et pellentesque porta, lacus leo gravida diam, at accumsan elit nisi id nisl. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce quis malesuada nisl. Maecenas auctor dui ut felis rhoncus hendrerit. Integer hendrerit nibh sit amet eros lobortis, non gravida lacus malesuada. Nam tristique ornare risus, eget hendrerit justo maximus quis. Proin ut nisl nec lacus laoreet gravida. Vivamus bibendum euismod magna sed fermentum. Etiam non bibendum nisi, ac dapibus dui.
      </p>
      <p>
        Duis eget est porta, porta ipsum vel, cursus dui. Maecenas ac dui ante. Proin eget eros non turpis imperdiet blandit lacinia vel diam. Aliquam facilisis, ligula sed consectetur congue, eros sapien feugiat nunc, non ultricies neque velit ac augue. Aliquam tincidunt urna et eros efficitur sagittis. In hac habitasse platea dictumst. Vivamus purus dolor, egestas eu lorem at, tristique ultricies augue. Sed in justo felis. Duis dapibus ante a tincidunt cursus.
      </p>
      <p>
        Curabitur volutpat, dolor sed pulvinar luctus, dolor diam pharetra nulla, sit amet tincidunt ipsum ipsum et felis. Mauris porta purus vitae ligula congue suscipit. Vivamus auctor neque nec nulla bibendum, vel semper ipsum tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. Curabitur ultricies tincidunt posuere. Duis facilisis turpis nibh, non elementum ipsum viverra et. Pellentesque auctor neque in nisl faucibus, non malesuada lorem porta. Duis tincidunt congue lorem, non tincidunt ligula congue id. Maecenas metus dolor, molestie nec odio at, varius dictum ligula. Ut eget tellus vitae lorem cursus dapibus sed in nunc. In eu tincidunt purus. Maecenas in nisi egestas, gravida tellus non, bibendum purus. Etiam ut luctus ante. Curabitur tellus risus, laoreet sed pellentesque dapibus, venenatis eget dui. Aliquam consectetur mattis pellentesque. Morbi vel eros sed dolor facilisis porta.
      </p>

    </div>
  </LandingLayout>
</template>

<script>
import LandingLayout from "@/layouts/LandingLayout";
export default {
  name: "TermAndCondition",
  components: {LandingLayout}
}
</script>

<style scoped>

</style>