<template>
  <div class="surface-0 overflow-hidden">
    <slot name="header">
      <Navbar/>
    </slot>
    <slot/>
    <slot name="footer">
      <Footer/>
    </slot>
  </div>
</template>

<script>
import Navbar from "@/components/Landing/Navbar";
import Footer from "@/components/Landing/Footer";

export default {
  name: "LandingLayout",
  components: {Footer, Navbar},
  created() {
    document.title = process.env.VUE_APP_TITLE
  },
}
</script>

<style scoped>
</style>