<template>
  <div class="tw-w-full md:tw-w-5/6 lg:tw-w-3/4 tw-flex tw-justify-between tw-items-center tw-h-24 tw-p-8"
       id="navbar">
    <div class="mt-4">
      <router-link to="https://salonial.com">
        <img alt="Logo" src="~@/assets/images/logo.png" width="90"/>
      </router-link>
    </div>
    <slot name="menu">
      <nav v-if="this.$route.path !== '/register'" class="tw-flex tw-text-white tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-semibold">
        <ul class="tw-flex tw-space-x-4 md:tw-space-x-8 tw-font-semibold">
          <li><a @click="smoothScroll('#about')">Tentang Kami</a></li>
          <li><a @click="smoothScroll('#feature')">Fitur</a></li>
          <li v-if="!user">
            <router-link :to="{name: 'register'}">
              Pendaftaran
            </router-link>
          </li>
          <li v-if="!user">
            <router-link :to="{name: 'login'}">
              Login
            </router-link>
          </li>
          <li v-else>
            <router-link :to="{name: 'dashboard'}">
              Dashbaord
            </router-link>
          </li>
        </ul>
      </nav>
    </slot>
  </div>
</template>

<script>
import { useAuthRole } from '@/composables/auth';

export default {
  name: "Navbar",
  setup() {
    const { getUser } = useAuthRole()
    return { getUser }
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return 'white';
      return 'dark';
    },
    topBarImage() {
      return "images/priti/logo-white.png";
    },
    user() {
      const user = this.getUser();
      return user;
    },
  },
  methods: {
    smoothScroll(id) {
      document.querySelector(id).scrollIntoView({
        behavior: 'smooth'
      });
    }
  },
}
</script>

<style scoped>
li:hover {
  cursor: pointer;
}
</style>