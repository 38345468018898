<template>
  <div class="tw-bg-white tw-border-t-2 tw-flex tw-flex-col-reverse lg:tw-flex-row tw-items-center lg:tw-items-start tw-justify-between tw-p-8 lg:tw-p-16">
    <div class="tw-flex flex-column tw-mt-16 lg:tw-mt-0 tw-items-start tw-w-5/6 lg:tw-w-1/4">
      <img style="width:120px" src="~@/assets/images/logo.png" alt="Salonial Logo" />
      <div class="mt-3">
        <p class="footer-text">
          <strong>PT Samden Cipta Digital</strong> <br />
          DEPOK TOWN SQUARE <br/>
          Jl. Margonda Raya No.1, Lt. 2 Mezzanine
          Kemiri Muka, Kota Depok,
          Jawa Barat 16424
        </p>
        <br />
        <p>
          <i><strong> Kembangkan bisnis salonmu bersama kami, perbanyak pemasukan bisnismu bersama Salonial!</strong></i>
        </p>  
      </div>

      <div class="flex flex-row mt-8">
        <img :src="'images/priti/twitter.png'" class="ml-2 mr-2" alt="Twitter" />
        <img :src="'images/priti/instagram.png'" class="ml-2 mr-2" alt="Instagram" />
        <img :src="'images/priti/facebook.png'" class="ml-2 mr-2" alt="Facebook" />
      </div>

      <div class="footer-text mt-8">
        <p>© Salonial 2022, All Rights Reserved</p>
      </div>
    </div>
    <div class="tw-flex tw-text-lg tw-flex-wrap tw-w-full lg:tw-w-2/4">
      <div class="tw-flex tw-flex-col tw-items-start ml-6 mr-6">
        <p class="mt-2 mb-4 tw-text-xl tw-font-semibold tw-text-blue-primary">Tentang kami</p>
        <p class="footer-text mt-2 mb-2">Aplikasi</p>
        <p class="footer-text mt-2 mb-2">Solutions</p>
        <p class="footer-text mt-2 mb-2">Blog</p>
        <p class="footer-text mt-2 mb-2">Company</p>
        
          <p class="footer-text mt-2 mb-2">
            <a href="https://salonial.com/syarat-dan-ketentuan-untuk-pengguna/" target="_blank">
            Syarat dan Ketentuan
            </a>
          </p>
     
     
          <p class="footer-text mt-2 mb-2">
            <a href="https://salonial.com/kebijakan-privasi/" target="_blank">
              Kebijakan Privasi
            </a>
          </p>
   
      </div>
      <div class="tw-flex tw-flex-col tw-items-start ml-6 mr-6">
        <p class="footer-text footer-title mt-2 mb-4 tw-text-xl tw-font-semibold tw-text-blue-primary">Mitra</p>
        <p class="footer-text mt-2 mb-2">Mitra</p>
        <p class="footer-text mt-2 mb-2">Gabung</p>
      </div>
      <div class="tw-flex tw-flex-col tw-items-start ml-6 mr-6">
        <p class="footer-text footer-title mt-2 mb-4 tw-text-xl tw-font-semibold tw-text-blue-primary">Fitur</p>
        <p class="footer-text mt-2 mb-2">Fitur</p>
        <p class="footer-text mt-2 mb-2">Unduh</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>
